function Soon(){
    return(
        <div className='external'>
            <div className='middle'>
                <div className='internal text-center'>
                    <h1>Coming Soon !</h1>
                </div>
            </div>
        </div>
    );
}

export default Soon;